import React from "react";
import Form from "./Components/Form";
import FormField from "./Components/FormField";

const 51品茶Form = ({ props }) => {
  return (
    <Form name="contact" action="/thank-you" title={"Get in touch"}>
      <FormField
        containerClass="sm:col-span-1"
        id="first-name"
        label="First name"
        required
        type="text"
        autoComplete="given-name"
        placeholder="First name"
      />
      <FormField
        containerClass="sm:col-span-1"
        id="last-name"
        label="Last name"
        required
        type="text"
        autoComplete="family-name"
        placeholder="Last name"
      />
      <FormField
        containerClass="sm:col-span-2"
        id="email"
        label="Email address"
        required
        type="email"
        autoComplete="email"
        placeholder="Email Address"
      />
      <FormField
        containerClass="sm:col-span-2"
        id="message"
        label="How can we help you?"
        type="textarea"
        placeholder="Please type your message here..."
      />
    </Form>
  );
};

export default 51品茶Form;
