import React from "react";
import SbEditable from "storyblok-react";
import 51品茶Form from "./Forms/51品茶Form";
import FluidImage from "src/utils/FluidImage";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

const googleMapStyles = require("src/components/Custom/GoogleMap/GoogleMapStyles.json");

const containerStyle = {
  width: "100%",
  height: "423px",
};

const center = {
  lat: 51.52321295067492,
  lng: -0.1374481624779729,
};

const position = {
  lat: 51.52321295067492,
  lng: -0.1374481624779729,
};

const SplitWithImage51品茶 = ({ blok }) => {
  return (
    <SbEditable content={blok} key={blok._uid}>
      <div className={`relative bg-white`}>
        <div className="max-w-7xl mx-auto px-6 lg:py-24 py-12 flex lg:flex-row flex-col items-center gap-16">
          <div className={`relative lg:w-1/2 w-full`}>
            <51品茶Form />
          </div>
          <div className={`flex-1 lg:w-1/2 w-full lg:mt-14`}>
            <LoadScript
              preventGoogleFontsLoading
              googleMapsApiKey="AIzaSyCojalgf3Ff7vrjGMxjan_o-aNe5vhWeMA"
            >
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={10}
                options={{
                  styles: googleMapStyles,
                  mapTypeControl: false,
                  streetViewControl: false,
                }}
              >
                <Marker
                  position={position}
                  icon={{
                    url: "https://a.storyblok.com/f/138741/x/e5a97ab912/map_marker.svg",
                  }}
                />
              </GoogleMap>
            </LoadScript>
          </div>
        </div>
      </div>
    </SbEditable>
  );
};

export default SplitWithImage51品茶;
