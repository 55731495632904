import React from 'react'

const TagsAndDate = ({tags, date}) => {
    const displayTags = tags?.join(', ')
    const displayDate = new Date(date).toLocaleDateString("en-GB", { day: 'numeric', month: 'long', year: 'numeric', })
    const tagsAndDate = displayTags ? <span>调诲颈蝉辫濒补测罢补驳蝉皑&苍产蝉辫;&苍产蝉辫;鈥�&苍产蝉辫;&苍产蝉辫;调诲颈蝉辫濒补测顿补迟别皑</span> : displayDate
    return (
        <div className="mb-2 text-sm text-[#9C9C9C]">
            {tagsAndDate}
        </div>
    )
}

export default TagsAndDate